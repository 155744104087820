import React from "react";
import Plx from "react-plx";

import "../assets/stylesheets/shared.scss";

import Layout from "../components/layout";
import Seo from "../components/seo";

import Header from "../components/header";
import GetInTouch from "../components/get-in-touch";
import Footer from "../components/footer";

import { parallaxCircles1, parallaxCircles2, parallaxRotate } from "../utils";

const IndexPage = () => (
  <Layout>
    <Seo title="Privacy Policy" keywords={[]} />
    <Header />
    <article className="page">
      <div className="container">
        <Plx parallaxData={parallaxCircles1} className="hero__circle" />
        <Plx parallaxData={parallaxRotate} className="page__circles" />
        <Plx parallaxData={parallaxCircles2} className="vision__circle" />

        <div className="circle page__circle-1" />
        <div className="circle page__circle-2" />
        <div className="circle page__circle-3" />
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="page__lead">
              <p className="g-subtitle">Afrivest</p>
              <h1 className="g-h2">Privacy and Cookie Policy</h1>
              <p className="g-p2">
                This Policy applies to collection of your personal data, as well
                as to the use of "cookie" files and similar  on the
                Afrivest.io website (the “Website”). The Website is operated
                by Afrivest Sp. z o.o., with its registered office in Warsaw,
                address: Grzybowska 62, 00-855 Warsaw, Poland, entered in the
                register of entrepreneurs of the National Court Register kept by
                the District Court for the capital city of Warsaw, 12th
                Commercial Division of the National Court Register under KRS
                number 0000498940, Tax Identification Number (NIP) 7010413977,
                REGON number 147115338, share capital of PLN 20 600
                ("Afrivest").
              </p>
            </div>
            <h2 className="g-h4">Your Privacy is important to us!</h2>
            <ul className="g-list">
              <li>
                <p className="g-p2 color-white">Contact data</p>
                <p className="g-p2">
                  In order to make contacting you possible, the Website enables
                  collection of certain personal data: name and surname, your
                  e-mail address. Aside from your personal data, Afrivest also
                  collects basic statistic data, e.g. location. Afrivest is the
                  controller of your personal data and takes appropriate steps
                  to ensure security of your data.
                </p>
              </li>
              <li>
                <p className="g-p2 color-white">Registration data</p>
                <p className="g-p2">
                  Afrivest also collects your personal data during the
                  registration process, through the form available at
                  https://afrivest.app. At registration, we request that you
                  provide us with the following personal data: first name and
                  e-mail.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Other data collected by us through the registration form,
                  which may constitute personal data, are collected and
                  processed on behalf of acquirers, who act as data controllers
                  and entrust us with the processing of personal data for
                  purpose of concluding agreement between you and the acquirers.
                  In such cases, we act as data processors.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  As data controller, we process your data for the purpose of
                  providing our services to you and for general marketing
                  purposes: (i) responding to your query; (ii) sending you
                  tailored offers; and (iii) sending you marketing e-mails and
                  contacting you telephonically, provided that you consented to
                  such forms of communication. Together with marketing
                  information and offers regarding Afrivest products and
                  services, we may at times approach you with information on
                  compatible products and services, which are offered together
                  with ours.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  In processing your data, we pursue our legitimate interest in
                  responding to your query, concluding contract with the entity
                  you represent and direct marketing of our services (the legal
                  basis for processing of your data).
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Provision of your personal data is voluntary, but if you
                  choose not to provide us with your basic contact information,
                  we may not be able to respond to your query or provide
                  services to the entity you represent.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Where we act as data controller, you have the right to object
                  to the processing of your data by us and to request that we
                  correct or delete your data. If you file such a request we
                  will stop processing your data and delete it, unless there
                  exists an important overriding legal interest (e.g. defense of
                  claims or existence of a legal obligation to retain the data)
                  which will entitle us to keep your data for a longer period.
                  You also have the right of access to your data, as well as the
                  right to data portability and to restriction of processing.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  We process your personal data only for as long as necessary
                  for the purposes, for which it was collected. In establishing
                  the retention period for your personal data, we take into
                  account, among others, the following criteria: (i) whether you
                  express continuous interest in our services, (ii) if you have
                  become our customer/the entity you represent entered into a
                  contract with us, (iii) if you told us during the call or in a
                  message that you would like us to contact you in the future.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  We entrust the processing of your personal data to entities,
                  which act on our behalf and solely based on our instructions
                  (data processors).
                </p>
              </li>
              <li>
                <p className="g-p2">
                  We may have to share your personal data with competent
                  authorities (in particular the police or prosecution service),
                  if they approach us with such a request, provided that there
                  exists an appropriate legal basis for making your personal
                  data available to them (e.g. a court order).
                </p>
              </li>
              <li>
                <p className="g-p2">
                  If you have any questions with regard to the processing of
                  your data by us or if you wish to exercise your rights, you
                  can contact our data protection contact@Afrivest.com. You also
                  have the right to file a complaint to data protection
                  authority
                </p>
              </li>
            </ul>

            <h2 className="g-h4">
              Our use of cookies and similar 
            </h2>
            <ul className="g-list">
              <li>
                <p className="g-p2">
                  In order to improve the operation of our site, we use cookies,
                  web beacons, device fingerprinting and similar .
                  We also make use of Google Analytics and Hotjar tools.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Cookies are the small text files / fragments of text that the
                  Website sends to the browser and which are sent back by the
                  browser at the next entrances to the website, for the purpose
                  of keeping the browser session or storing user page data.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Web beacons (also known as pixel tags) are transparent image
                  files used to monitor your journey around a single interact
                  with the Website and its content.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  We also use Hotjar tool on our Website. Hotjar monitors and
                  stores users' behaviour on the website. Hotjar processes the
                  following data: navigation, website scrolling, cursor
                  movement. Other data such as location, device id, operating
                  system, browser and cookies are also processed.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Google Analytics is a tool we use in order to calculate visits
                  to our website, check which functionalities of our website are
                  used most and improve the operation of the Website.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Device fingerprinting is a technology which allows us to
                  collect information about your device (what browser or
                  operating system you use, plug-ins, time zone, other
                  settings). We use this information to identify you and to
                  ensure security of our website.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Exclusion or limitation of the use of cookies and other
                  similar  may affect the functionality of the
                  Website.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  The following types of cookies are used by the Website –
                  ‘session cookies’ which are erased when the user logs out or
                  closes the browser and ‘persistent cookies’ which remain on
                  the user's device for a pre-defined period of time or until
                  the user deletes them manually.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  The Website uses cookies for statistical purposes, to adapt
                  the website content, performance and structure to users’
                  individual preferences, to provide users with advertising
                  content adapted to their preferences. Cookies and web beacons
                  for ads targeting purposes can be linked to other online
                  services, such as Facebook. Please note that cookies may
                  collect personal information to enhance the site's
                  functionality. Such data is stored in encrypted form and the
                  access to them is limited.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Cookies that are used by the third parties via the Website,
                  including in particular the users of the website, are subject
                  to their own privacy policy.
                </p>
              </li>
              <li>
                <p className="g-p2">
                  Handling and use of "cookies" is allowed automatically.
                  However, these settings can be changed at any time. For
                  details on how cookies are handled and used, see the settings
                  of your web browser. You can learn more about cookies and
                  similar  and check how to disable them for the
                  browser you use by visiting the following website: 
                  <a
                    href="http://www.allaboutcookies.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.allaboutcookies.org
                  </a>
                  .
                </p>
              </li>
              <li>
                <p className="g-p2">
                  If you wish to learn more about Google Analytics tools and
                  your privacy, please visit the following site:{" "}
                  <a
                    href="https://support.google.com/analytics/answer/6004245?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.google.com/analytics/answer/6004245?hl=en
                  </a>
                  . You can also opt out from Google Analytics by installing a
                  browser add-on available here: 
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://tools.google.com/dlpage/gaoptout?hl=en-GB
                  </a>
                  .
                </p>
              </li>
              <li>
                <p className="g-p2">
                  To learn more about Hotjar and how to opt out, please visit
                  the following websites:{" "}
                  <a
                    href="https://www.hotjar.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.hotjar.com/privacy
                  </a>
                   and 
                  <a
                    href="https://www.hotjar.com/opt-out"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.hotjar.com/opt-out
                  </a>
                  .
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
    <GetInTouch />
    <Footer />
  </Layout>
);

export default IndexPage;
